.orbiting-circles-container {
    position: relative;
    width: 400px;
    height: 400px;
    margin: 0 auto;
    background-color: #ffffff;
    margin-top: -80px;
  }
  
  .orbiting-circles {
    position: relative;
    z-index: 1;
    width: 500px;
    height: 500px;
  }
  
  @media (max-width: 768px) {
    .orbiting-circles-container {
      width: 400px;
      height: 400px;
    }
    
    .orbiting-circles {
      width: 400px;
      height: 400px;
    }
  } 